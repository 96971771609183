





























/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import lang from '@lang/age.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
    },
    props: [],
    data() {
        return {
            pageData: {},
            ages: [
                '<20',
                '20 - 30',
                '31 - 40',
                '41 - 50',
                '51>'
            ]
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        },
        ...mapState(['sessionData']),
    },
    methods: {
        handleClick(age) {
            this.setData('age', age);
            this.$router.push('/finish');
        }
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
