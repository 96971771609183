export default {
    en: {
        title: 'AGE',
        intro: 'Some say "knitting is for grannies". Is that true? We’re curious to know how old you are',
        empty: 'I’d rather not say'
    },
    nl: {
        title: 'LEEFTIJD',
        intro: '"Breien is voor oma’s", horen we wel eens. Maar klopt dat wel? Wij zijn heel benieuwd hoe oud je bent',
        empty: 'Zeg ik liever niet'
    }
};